<template>
  <div>
    <div
      v-for="(seccion,index) in seccionesFormulario"
      :key="index"
    >
      <b-card
        class="classSeccion flex-grow-1 p-2 mb-1"
      >
        <div
          class="cabezeraSeccion"
        >
          Sección {{ index + 1 }} de {{ seccionesFormulario.length }}
        </div>
        <div class="m-0 p-0">
          <h3>{{ seccion.titulo }}</h3>
          <em><h5>{{ seccion.subtitulo }}</h5></em>
        </div>
        <br>
      </b-card>
      <div
        v-for="(pregunta,indexPregunta) in seccion.preguntas"
        :key="indexPregunta"
      >
        <b-card class="ml-2">
          <h5>{{ index + 1 }}.{{ indexPregunta + 1 }} {{ pregunta.pregunta }}</h5>
          <em><h6>{{ pregunta.descripcion }}</h6></em>
          <PreguntasResumen

            :respuestas-pregunta="pregunta.respuestax"
            :tipo-pregunta="pregunta.tipo"
            :pregunta-detalles="pregunta.detalles"
            :pregunta-compuesta="pregunta.compuesta"
          />
        </b-card>

      </div>
    </div>

  </div>
</template>

<script>import {
  BCard, VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import PreguntasResumen from './PreguntasResumen.vue'

export default {
  components: {
    BCard,
    // extern
    PreguntasResumen,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },

  props: {
    seccionesFormulario: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      preguntas: [],

    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.classSeccion {
border-top: #2b942b 10px solid;
}
.herramientas{
  background-color: white;
  border-radius: 5px;
}
.cabezeraSeccion{
  background-color: #2b942b;
  left: -42px;
  position: relative;
  top: -52px;
  width: 150px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 12px;
}
</style>
