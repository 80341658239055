<template>
  <div class="mx-2">
    <span v-if="cantRespuestas > 0">
      <secciones-resumen
        :secciones-formulario="seccionesFormulario"
        :dato-actualizar="datoActualizar"
      />
    </span>
    <span v-else>
      <h3 class="text-center">
        <span class="bg-light-danger px-1 rounded"> No existe respuestas</span>
      </h3>
    </span>

  </div>
</template>

<script>

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import SeccionesResumen from './SeccionesResumen.vue'
import resumenStoreModule from './resumenStoreModule'

export default {
  components: {
    SeccionesResumen,
  },

  props: {
    datoActualizar: {
      type: Number,
      default: 0,
    },
    idFormulario: {
      type: Number,
      default: 0,
    },
    cantRespuestas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      seccionesFormulario: [],
    }
  },

  created() {
    store.dispatch('ResumenRespuestasModule/getRespuestasResumen', this.idFormulario).then(res => {
      this.seccionesFormulario = res.data.secciones
    })
  },
  mounted() {

  },
  methods: {

    actualizarFormulario() {
      store.dispatch('ResumenRespuestasModule/updateFormulario', this.formulario).then(res => {
        this.$set(this.formulario, 0, res.data)
      })
    },

  },

  setup() {
    const RESUMEN_RESPUESTAS_STORE_MODULE_NAME = 'ResumenRespuestasModule'

    // Register module
    if (!store.hasModule(RESUMEN_RESPUESTAS_STORE_MODULE_NAME)) store.registerModule(RESUMEN_RESPUESTAS_STORE_MODULE_NAME, resumenStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESUMEN_RESPUESTAS_STORE_MODULE_NAME)) store.unregisterModule(RESUMEN_RESPUESTAS_STORE_MODULE_NAME)
    })
    return {
      resumenStoreModule,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
