<template>
  <div class="mx-2">
    <b-row v-if="cantRespuestas > 0">

      <b-col
        cols="12"
        class="mt-1"
      >
        <div
          class="d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="flat-primary"
            class="btn-icon rounded-circle mx-1"
            @click="() => usuarioNumero <= 1 ? usuarioNumero=1 : usuarioNumero--"
          >
            <feather-icon
              icon="ChevronsLeftIcon"
              size="20"
            />
          </b-button>
          <span>
            <b-form-input
              v-model="usuarioNumero"
              type="number"
              style="width: 50px;"
              class="pt-0 pb-0"
              @input="inputModificado"
            />
          </span>
          <span class="ml-1"> de {{ cantUsuarios }}</span>
          <b-button
            variant="flat-primary"
            class="btn-icon rounded-circle mx-1"
            @click="()=>usuarioNumero === cantUsuarios ? cantUsuarios : usuarioNumero++"
          >
            <feather-icon
              icon="ChevronsRightIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
      <b-col class="mt-1">
        <b-card>
          <FormularioVistaLectura
            :key="componentKey"
            :id-formulario="idFormulario"
            :id-usuario="usuarioId"
          />
        </b-card>
      </b-col>
    </b-row>
    <span v-else>
      <h3 class="text-center">
        <span class="bg-light-danger px-1 rounded"> No existe respuestas</span>
      </h3>
    </span>
  </div>

</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BCard,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import FormularioVistaLectura from '@/views/administrador/gestion-formulario/vista-lectura/FormularioVistaLectura.vue'
import preguntaRespuestaStoreModule from './individualRespuestaStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    FormularioVistaLectura,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
    cantRespuestas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      usuarioNumero: 1,
      cantUsuarios: 0,
      options: [],
      preguntaSeleccionada: {},
      datoActualizar: 0,

      usuarioId: '',
      componentKey: 0,
    }
  },
  watch: {
    usuarioNumero() {
      this.actualizarRespuestasIndividual()
    },
  },
  created() {

  },
  async mounted() {
    // recuperamos las preguntas y sus ids
    await store.dispatch('preguntaRespuestasModule/getDataFormulario', this.idFormulario)
      .then(res => {
        if (res.data.n_respuestas > 0) {
          this.cantUsuarios = res.data.usuarios.length
          const ARRAY_PREGUNTAS = res.data.usuarios.map((value, index) => ({ id: value, value: index + 1, text: `${index + 1}. ${value}` }))
          this.options = [...ARRAY_PREGUNTAS]
          this.actualizarRespuestasIndividual()
        }
      })
  },

  methods: {
    actualizarRespuestasIndividual() {
      if (this.options.find(val => val.value === parseInt(this.usuarioNumero, 10))) {
        this.usuarioId = `${this.options.find(val => val.value === parseInt(this.usuarioNumero, 10)).id}`
        this.forceRerenderVistaLectura()
      }
    },
    forceRerenderVistaLectura() { // se esta usando este metodo debido a que los graficos se muestra solo cuando se le fuerza a renderizar nuevamente
      this.componentKey += 1
    },
    inputModificado() {
      this.actualizarRespuestasIndividual()
    },
  },

  setup() {
    const PREGUNTA_RESPUESTAS_STORE_MODULE_NAME = 'preguntaRespuestasModule'

    // Register module
    if (!store.hasModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)) store.registerModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME, preguntaRespuestaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)) store.unregisterModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)
    })
    return {
      preguntaRespuestaStoreModule,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
