<template>
  <div>
    <div v-if="tipoPregunta === 'respuesta_corta' || tipoPregunta === 'parrafo' || tipoPregunta === 'fecha' || tipoPregunta === 'hora'">
      <b-list-group>
        <b-list-group-item
          v-for="(respuesta,index) in respuestasPregunta"
          :key="index"
        >{{ respuesta.respuesta }}</b-list-group-item>
      </b-list-group>
    </div>
    <div v-else-if="tipoPregunta === 'varias_opciones' || tipoPregunta === 'desplegable' || tipoPregunta === 'mixto_varias_opciones' || tipoPregunta === 'mixto_desplegable'">

      <ApexDonutChart
        :series="obtenerSeries()"
        :labels="obtenerLabels()"
      />
    </div>
    <div v-else-if="tipoPregunta === 'casillas' || tipoPregunta === 'mixto_casillas'">

      <ApexBarChart
        :series="obtenerSeriesBar()"
        :labels="obtenerLabelsBar()"
      />
    </div>

    <div v-else-if="tipoPregunta === 'archivo'">

      <b-list-group>
        <b-list-group-item
          v-for="(respuesta,index) in respuestasPregunta"
          :key="index"
        >
          <b-button
            size="sm"
            :href="respuesta.respuesta"
            target="_blank"
          >Archivo {{ index + 1 }}
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </div>

  </div>
</template>

<script>import {
  BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

import ApexDonutChart from '@/views/components/graficos/apex-chart/ApexDonutChart.vue'
import ApexBarChart from '@/views/components/graficos/apex-chart/ApexBarChart.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,

    ApexDonutChart,
    ApexBarChart,
  },

  props: {
    respuestasPregunta: {
      type: Array,
      default: () => [],
    },
    tipoPregunta: {
      type: String,
      default: '',
    },
    preguntaDetalles: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      componentKey: 0,

      seriesBar: [],
      labelsBar: [],
    }
  },

  methods: {

    obtenerSeries() {
      const data = []
      this.preguntaDetalles.forEach(value => {
        const cant = this.respuestasPregunta.filter(val => value.id === parseInt(val.respuesta, 10)).length
        // apexDonutChart
        data.push(cant)
      })
      return data
    },
    obtenerLabels() {
      const data = []
      this.preguntaDetalles.forEach(value => {
        data.push(`${value.respuesta}`)
      })
      return data
    },
    obtenerSeriesBar() {
      const data = []
      let respuestasCasillas = []
      this.respuestasPregunta.forEach(value => {
        respuestasCasillas = [...value.respuesta.split('[~]'), ...respuestasCasillas]
      })

      this.preguntaDetalles.forEach(value => {
        const cant = respuestasCasillas.filter(val => value.id === parseInt(val, 10)).length

        data.push(cant)
      })
      return data
    },
    obtenerLabelsBar() {
      const data = []
      let respuestasCasillas = []
      this.respuestasPregunta.forEach(value => {
        respuestasCasillas = [...value.respuesta.split('[~]'), ...respuestasCasillas]
      })

      this.preguntaDetalles.forEach(value => {
        data.push(`${value.respuesta}`)
      })
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.classSeccion {
border-top: #048975 10px solid;
}
.herramientas{
  background-color: white;
  border-radius: 5px;
}
.cabezeraSeccion{
  background-color: #048975;
  left: -42px;
  position: relative;
  top: -52px;
  width: 150px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 12px;
}
</style>
