<template>
  <b-card>
    <vue-apex-charts
      type="donut"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import apexChartData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,

  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // apexChartData,

      chartOptions: {
        legend: {
          show: true,
          position: 'right',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          '#FE9A2E',
          '#0000FF',
          '#01DF01',
          '#FE2E2E',
          '#ffe700',
          '#00d4bd',
          '#826bf8',
          '#2b9bf4',
          '#FFA1A1',
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
          // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: '',
                  formatter() {
                    return ''
                  },
                },
              },
            },
          },
        },
        labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'top',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },

    }
  },
  beforeMount() {
    // this.apexChartData.donutChart.series = JSON.parse(JSON.stringify(this.series))
    this.chartOptions.labels = JSON.parse(JSON.stringify(this.labels))
  },
}
</script>
