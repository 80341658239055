<template>
  <div class="mx-2">
    <b-row v-if="cantRespuestas>0">
      <b-col cols="12">
        <b-form-select
          v-model="preguntaNumero"
          :options="options"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-1"
      >
        <div
          class="d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="flat-primary"
            class="btn-icon rounded-circle mx-1"
            @click="() => preguntaNumero <= 1 ? preguntaNumero=1 : preguntaNumero--"
          >
            <feather-icon
              icon="ChevronsLeftIcon"
              size="20"
            />
          </b-button>
          <span class="mr-1"> Pregunta </span>
          <span>

            <b-form-input
              v-model="preguntaNumero"
              type="number"
              style="width: 50px;"
              class="pt-0 pb-0"
            />
          </span>
          <span class="ml-1"> de {{ cantPreguntas }} </span>
          <span class="ml-1"> preguntas</span>
          <b-button
            variant="flat-primary"
            class="btn-icon rounded-circle mx-1"
            @click="()=>preguntaNumero === cantPreguntas ? cantPreguntas : preguntaNumero++"
          >
            <feather-icon
              icon="ChevronsRightIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
      <b-col class="mt-1">
        <b-card :key="actualizarComponente ? 1 : 0">
          <PreguntasResumen
            :respuestas-pregunta="preguntaSeleccionada.respuestax"
            :tipo-pregunta="preguntaSeleccionada.tipo"
            :pregunta-detalles="preguntaSeleccionada.detalles"
            :pregunta-compuesta="preguntaSeleccionada.compuesta"
          />
        </b-card>
      </b-col>
    </b-row>
    <span v-else>
      <h3 class="text-center">
        <span class="bg-light-danger px-1 rounded"> No existe respuestas</span>
      </h3>
    </span>
  </div>

</template>

<script>
import {
  BFormSelect, BRow, BCol, BFormInput, BButton, BCard,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
// import { heightTransition } from '@core/mixins/ui/transition'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PreguntasResumen from '../resumen/PreguntasResumen.vue'

// import { mapState } from 'vuex'

// import SeccionesResumen from './SeccionesResumen.vue'
import preguntaRespuestaStoreModule from './preguntaRespuestaStoreModule'

export default {
  components: {
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,

    PreguntasResumen,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
    cantRespuestas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      preguntaNumero: 1,
      cantPreguntas: 0,
      // selected: null,
      options: [],
      preguntaSeleccionada: {},
      actualizarComponente: false,
    }
  },
  watch: {
    preguntaNumero() {
      if (this.options.find(val => val.value === parseInt(this.preguntaNumero, 10))) {
        this.actualizarRespuestasPregunta()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'No existe una pregunta con el número ingresado',
            variant: 'warning',
          },
        })
      }
    },
  },
  created() {

  },
  async mounted() {
    // recuperamos las preguntas y sus ids
    await store.dispatch('preguntaRespuestasModule/getDataFormulario', this.idFormulario)
      .then(res => {
        if (res.data.n_respuestas > 0) {
          this.cantPreguntas = res.data.preguntas.length

          const ARRAY_PREGUNTAS = res.data.preguntas.map((value, index) => ({ id: value.id, value: index + 1, text: `${index + 1}. ${value.pregunta}` }))
          this.options = [...ARRAY_PREGUNTAS]
          this.actualizarRespuestasPregunta()
        }
      })
  },

  methods: {
    async actualizarRespuestasPregunta() {
      await store.dispatch('preguntaRespuestasModule/getRespuestasPregunta',
        {
          idFormulario: this.idFormulario,
          idPregunta: this.options.find(val => val.value === parseInt(this.preguntaNumero, 10)).id,

        }).then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.preguntaSeleccionada = res.data.secciones[0].preguntas[0]
      })
      this.actualizarComponente = !this.actualizarComponente
    },

  },

  setup() {
    const PREGUNTA_RESPUESTAS_STORE_MODULE_NAME = 'preguntaRespuestasModule'

    // Register module
    if (!store.hasModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)) store.registerModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME, preguntaRespuestaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)) store.unregisterModule(PREGUNTA_RESPUESTAS_STORE_MODULE_NAME)
    })
    return {
      preguntaRespuestaStoreModule,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
