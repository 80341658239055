<template>
  <div>
    <div class="d-flex justify-content-center my-2 align-items-center bg-light-primary">

      <h2 class="text-primary py-1">
        {{ cantRespuestas }} respuestas
      </h2>
    </div>

    <b-tabs
      content-class="mt-2"
      justified
      align="center"
      pills
    >

      <b-tab
        title="Resumen"
        active
      >
        <RespuestasResumen
          :id-formulario="idFormulario"
          :cant-respuestas="cantRespuestas"
        />
      </b-tab>
      <b-tab
        title="Por pregunta"
      >
        <b-card-text>
          <RespuestasPregunta
            :id-formulario="idFormulario"
            :cant-respuestas="cantRespuestas"
          />

        </b-card-text>

      </b-tab>
      <b-tab title="Individual">
        <b-card-text>
          <RespuestasIndividual
            :id-formulario="idFormulario"
            :cant-respuestas="cantRespuestas"
          />
        </b-card-text>

      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RespuestasResumen from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/resumen/RespuestasResumenIndex.vue'
import RespuestasPregunta from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/pregunta/RespuestasPreguntaIndex.vue'
import RespuestasIndividual from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/individual/RespuestasIndividualIndex.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCardText,

    // extern
    RespuestasResumen,
    RespuestasPregunta,
    RespuestasIndividual,
  },
  directives: {
    Ripple,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cantRespuestas: 0,
    }
  },
  created() {
    this.$http.get(`/recursos/formulario/${this.idFormulario}/data`).then(res => {
      this.cantRespuestas = res.data.n_respuestas
    })
  },

}
</script>

<style>

</style>
