<template>
  <b-card>
    <vue-apex-charts
      type="bar"
      height="350"
      :options="chartOptions"
      :series="seriesBar"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import apexChartData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,

  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      seriesBar: [{
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
            'United States', 'China', 'Germany',
          ],
        },
      },

    }
  },
  beforeMount() {
    // this.apexChartData.donutChart.series = JSON.parse(JSON.stringify(this.series))
    this.chartOptions.xaxis.categories = JSON.parse(JSON.stringify(this.labels))
    this.seriesBar[0].data = this.series
  },
}
</script>
